/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import BannerHomepage from "./BannerHomepage";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import StarRatingComponent from "react-star-rating-component";
import { NavLink, Link, useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Icon2 from "../../assets/images/icons/icon2.svg";
import Empty from "../../assets/images/appImages/category.png";
import AliceCarousel from "react-alice-carousel";
import { getImage, truncateCaractere, formatCurrency } from "../../utils/Utils";
import {
  useGetProductListQuery,
  useGetProductsEnAvantQuery,
} from "../../utils/api/product/product.api";
import { useLazyFavoriteByUserQuery } from "../../utils/api/favories/favorie.api";
import { useGetAllCategoryListQuery } from "../../utils/api/category/category.api";
import { usePagination, useSearch } from "../../utils/hooks";
import { ApiBaseUrl } from "../../utils/http";
import axios from "axios";
import { useAppSelector, useAppDispatch } from "../../redux/hook";
import Swal from "sweetalert2";
import { Color } from "../../utils/theme";
import { ProductItemsSkeleton } from "../user/ParcoursAchat/ParcoursAchat";
import { useGetVendeurByApiKeyQuery } from "../../utils/api/product/product.api";
import { VendeurApikey } from "../../utils/http";
import { AppLocalStorage } from "../../utils/storage";
import { onSetUserToken } from "../../redux/slice/User.slice";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const responsiveI = {
  0: { items: 2 },
  992: { items: 3 },
  1024: { items: 4 },
};

export const responsiveII = {
  0: { items: 2 },
  992: { items: 3 },
  1024: { items: 7 },
};

const CategorieSkeleton = () => {
  return (
    <>
      {Array(4)
        .fill(4)
        .map((el, i) => (
          <div className="col-lg-3 col-md-3 mb-3 dis-flex" key={i}>
            <div className="item-stat-dashboad-view ">
              <div className="content-item-stat-dashboad-view mt-2">
                <Skeleton
                  className="titre-item-stat-dashboad-view mb-2"
                  width={`70%`}
                />
              </div>
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <Skeleton
                      className="chiffre-item-stat-dashboad-view mb-1"
                      width={`70%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

const HomePage = () => {
  const dispatch = useAppDispatch();
  const Remember = AppLocalStorage.getItem("remember");
  const UserRemember: any = AppLocalStorage.getItem("user");

  const { limit, page } = usePagination(12);
  let [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");
  const { data = { results: [] }, isLoading } = useGetProductListQuery({
    page: page,
    limit: limit,
  });

  const { data: produitsEnAvant } = useGetProductsEnAvantQuery({
    page: page,
    limit: limit,
  });

  const { data: categories, isLoading: isLoadingCategorie } =
    useGetAllCategoryListQuery();
  React.useEffect(() => {
    if (status == "success") {
      Swal.fire({
        icon: "success",
        title: `Paiement effectué avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    if (status == "error") {
      Swal.fire({
        icon: "error",
        title: "Erreur est survenue lors du paiement !",
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [status]);

  React.useEffect(() => {
    if (Remember) {
      dispatch(
        onSetUserToken({
          user: UserRemember?.user,
          token: UserRemember?.token,
        })
      );
    }
  }, [Remember]);

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <BannerHomepage />
        <div className="container-easy-market mb-5">
          <div className="container-category-display-mobile">
            <div className="content-title-home-page">
              <div className="title-home-page-container">
                <Fade bottom>
                  <h3 className="title-home-page-theme text-center">
                    Nos catégories de produits
                  </h3>
                </Fade>
              </div>
            </div>
            <div className="row">
              {isLoadingCategorie && <CategorieSkeleton />}
            </div>
            <div className="container-list-category-product">
              <AliceCarousel
                mouseTracking
                responsive={responsiveII}
                autoPlayStrategy="default"
                controlsStrategy="alternate"
                autoPlay={true}
                infinite={true}
                keyboardNavigation={true}
                autoPlayInterval={6000}
              >
                {categories?.results?.map((item) => (
                  <Fade bottom>
                    <Link
                      state={{ catgoryId: item?.id }}
                      className="link-footer link-footer-deco trans-0-2 d-block"
                      to={`/produits?categorie=${item?.slug}`}
                    >
                      <div
                        className="category-product-accueil-item position-relative"
                        key={item.id}
                      >
                        <div className="content-icon-category-product-accueil">
                          {item?.images ? (
                            <img
                              loading="lazy"
                              className="catgoryImg"
                              src={item?.images ? getImage(item?.images) : Empty}
                              alt={item?.slug}
                            />
                          ) : (
                            <img loading="lazy" src={Icon2} alt={item?.slug} />
                          )}
                        </div>
                        <div className="title-category-product-item-mobile">
                          {item?.nom}
                        </div>
                      </div>
                    </Link>
                  </Fade>
                ))}
              </AliceCarousel>
            </div>
          </div>
          <div className="container-home-page mb-3 my-5 container-home-page-nos-produits-carousel">
            <div className="content-title-home-page pb-5">
              <div className="title-home-page-container">
                <Fade bottom>
                  <h3 className="title-home-page-theme text-center">
                    Nouveaux produits
                  </h3>
                </Fade>
              </div>
            </div>
            <div className="row">{isLoading && <ProductItemsSkeleton />}</div>
            <div className="">
              <AliceCarousel
                mouseTracking
                responsive={responsiveI}
                autoPlayStrategy="default"
                controlsStrategy="alternate"
                autoPlay={true}
                infinite={true}
                keyboardNavigation={true}
                autoPlayInterval={6000}
              >
                {produitsEnAvant?.count && produitsEnAvant?.count > 0
                  ? !!produitsEnAvant &&
                  produitsEnAvant?.results
                    ?.slice(
                      Math.max(produitsEnAvant?.results?.length - 10, 0)
                    )
                    ?.map((produit, key) => (
                      <div
                        className="col-product-item"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <ProductItem item={produit} key={key} />
                      </div>
                    ))
                  : !!data &&
                  data?.results
                    ?.slice(Math.max(data?.results?.length - 10, 0))
                    ?.map((produit, key) => (
                      <div className="col-product-item product-item-carousel">
                        <ProductItem item={produit} key={key} />
                      </div>
                    ))}
              </AliceCarousel>
            </div>
          </div>
          <div className="container-home-page my-5 container-home-page-nos-produits">
            <div className="content-title-home-page pb-5">
              <div className="title-home-page-container">
                <Fade bottom>
                  <h3 className="title-home-page-theme text-center">
                    Nos Produits
                  </h3>
                </Fade>
              </div>
            </div>
            <div className="container-list-product-home">
              <Row>
                {isLoading && <ProductItemsSkeleton />}
                {!!data &&
                  data?.results
                    ?.slice(Math.max(data?.results?.length - 12, 0))
                    ?.map((produit, key) => (
                      <Col
                        sm={6}
                        xs={6}
                        md={4}
                        lg={3}
                        className="col-content-product-home mb-4 pb-4"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div className="product-item-carousel">
                          <ProductItem item={produit} key={key} />
                        </div>
                      </Col>
                    ))}
              </Row>
            </div>
            <div className="content-btn-title-home-page flex-r">
              <Fade right>
                <NavLink to="/produits" className="btn btn-see-more-product">
                  Voir plus
                </NavLink>
              </Fade>
            </div>
          </div>
        </div>
        <div className="py-5">
          <div className="content-title-home-page pb-5">
            {/* <div className="title-home-page-container">
                <Fade bottom>
                  <h3 className="title-home-page-theme text-center">
                    Ce que dises nos clients
                  </h3>
                </Fade>
              </div> */}
          </div>
          {/* <Testimonial /> */}
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default HomePage;

export const ProductItem = ({ item }: { item: any }) => {
  const { user, token } = useAppSelector((s) => s?.user);
  const Vendeur = getVendeur();

  const imgProd =
    item?.type == "taille_unique"
      ? item?.images[0]?.image
      : item?.variations && item?.variations[0]?.images
        ? item?.variations[0]?.images[0]?.image
        : null;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [findBySlug, { data, isLoading }] = useLazyFavoriteByUserQuery();

  React.useEffect(() => {
    if (user?.slug) {
      findBySlug(user?.slug as string);
    }
  }, [user]);

  const handleAddFavoris = (id) => {
    let favorie = data?.find((x) => x?.produit?.id == id);

    let fd = new FormData();

    fd.append("user", user?.id);
    fd.append("produit", id);

    if (favorie) {
      axios
        .delete(ApiBaseUrl + `/api/favoris/${favorie?.slug}`, config)
        .then((response) => {
          findBySlug(user?.slug as string);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    } else {
      axios
        .post(ApiBaseUrl + "/api/favoris/", fd, config)
        .then((response) => {
          findBySlug(user?.slug as string);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  const isFavorite = (id) => {
    let favorie = data?.find((x) => x?.produit?.id == id);

    if (favorie) {
      return true;
    } else {
      return false;
    }
  };

  const onToggleFavorite = (id) => {
    if (!user?.slug) {
      Swal.fire({
        icon: "info",
        title: `Veuilliez vous connecter pour ajouter un produit au favoris .`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 6000,
      });
    } else {
      handleAddFavoris(id);
    }
  };

  return (
    <Fade bottom>
      <div className="product-item-container" style={{ flexGrow: 1 }}>
        <NavLink
          to={`/produit/${item?.slug}`}
          state={item}
          className="no-link w-100"
        >
          {item?.new === true ? (
            <span className="statut-product-item">Nouveau</span>
          ) : item?.stock == 0 ? (
            <span className="statut-product-rupture">Rupture de stock</span>
          ) : (
            ""
          )}
          {(item?.categorie?.promotion ||
            item?.sous_categorie?.promotion ||
            item?.promotion) && (
              <span className="statut-product-item">
                Promo -
                {item?.categorie?.promotion?.taux ||
                  item?.sous_categorie?.promotion?.taux ||
                  item?.promotion?.taux}
                %
              </span>
            )}

          <div className="content-img-product-item">
            <img
              loading="lazy"
              src={imgProd ? getImage(imgProd) : Empty}
              alt={item?.nom}
              className="img-product-item"
            />
          </div>
          <div className="container-body-product-item">
            <div className="content-element-body-product-item">
              <p className="name-product-item my-2">
                {window.innerWidth < 720
                  ? truncateCaractere(item?.nom, 39)
                  : truncateCaractere(item?.nom, 72)}
              </p>
              <div className="content-price-product">
                {item?.categorie?.promotion ||
                  item?.sous_categorie?.promotion ||
                  item?.promotion ? (
                  <>
                    <span className="price-product-item-promo pb-3">
                      {formatCurrency(
                        item?.prix -
                        (item?.prix *
                          (item?.categorie?.promotion?.taux ||
                            item?.sous_categorie?.promotion?.taux ||
                            item?.promotion?.taux)) /
                        100,
                        Vendeur?.devise
                      )}{" "}
                    </span>
                    <span className="price-product-item-bare pb-3">
                      {formatCurrency(item?.prix, Vendeur?.devise)}
                    </span>
                  </>
                ) : (
                  <p className="price-product-item d-flex">
                    {formatCurrency(item?.prix, Vendeur?.devise)}
                  </p>
                )}
              </div>
            </div>
            <div className="container-cta-chat-produit">
              {item?.stock > 0 ? (
                <div className="content-btn-achat-produit w-100">
                  <NavLink
                    to={`/produit/${item?.slug}`}
                    className="btn btn-theme w-100"
                  >
                    Acheter
                  </NavLink>
                </div>
              ) : (
                <div className="content-btn-achat-produit w-100">
                  <button className="btn btn-theme-outline w-100 disabled">
                    Acheter
                  </button>
                </div>
              )}
              {/* <div className="content-btn-add-to-panier">
                {item.rupture === false ? (
                <button 
                  className={`
                    btn btn-theme-outline ${isAddedToCart ? 'added_to_card' : ''}
                  `}
                  onClick={() => setIsAddedToCart(!isAddedToCart)}
                >
                  {isAddedToCart ? <BsCartCheck /> : <FiShoppingCart />}
                </button>
                 ) : (
                  <button 
                  className="btn btn-theme-outline disabled"
                >
                  {isAddedToCart ? <BsCartCheck /> : <FiShoppingCart />}
                </button>
                 )}
              </div> */}
            </div>
          </div>
        </NavLink>
        <button
          disabled={isLoading}
          className="btn icon-fav-product"
          id={item?.id}
          aria-label="Ajouter au favoris"
          onClick={() => onToggleFavorite(item?.id)}
        >
          {isFavorite(item?.id) ? (
            <AiFillHeart style={{ color: "#E58924" }} />
          ) : (
            <AiOutlineHeart />
          )}
        </button>
      </div>
    </Fade>
  );
};

export const ProductItemSkeleton = () => {
  return (
    <>
      {Array(12)
        .fill(12)
        .map((item, i) => (
          <div className="col-product-item" key={i}>
            <div
              className="product-item-container"
              style={{ border: "1px solid #ebebeb", textAlign: "center" }}
            >
              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={`50%`}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={`60%`}
                  height={20}
                />
                <Skeleton
                  className="name-prduct fs-15"
                  width={`70%`}
                  height={15}
                />
                <div className="content-rate-product">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={0}
                    starColor="#000000"
                    emptyStarColor="#D4D6D5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export const getVendeur = () => {
  const { data: vendeur } = useGetVendeurByApiKeyQuery(VendeurApikey);

  return vendeur;
};
